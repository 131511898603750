/* You can add global styles to this file, and also import other style files */
/* Jan 25 */
@import "variables";
@import "https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css"; 
body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.ant-card {
  border-radius: $mocksalaCardRadious !important;
  box-shadow: $mocksalaShadow;
  .ant-card-actions {
    border-radius: 0 0 $mocksalaCardRadious $mocksalaCardRadious !important;
  }
  .ant-card-head {
    border-radius: $mocksalaCardRadious $mocksalaCardRadious 0 0 !important;
    background: rgb(220 239 245);
    .ant-card-extra {
      a {
        color: $mocksalaSecondaryColor;
      }
    }
    .ant-card-head-title {
      font-weight: bold;
    }
  }
}

.ant-card:hover {
  box-shadow: $mocksalaShadowHover;
}
.ant-card.no-color {
  .ant-card-head {
    background: #ffffff;
  }
}

.ant-collapse {
  border-radius: $mocksalaCardRadious !important;
  box-shadow: $mocksalaShadow;
  .ant-collapse-actions {
    border-radius: 0 0 $mocksalaCardRadious $mocksalaCardRadious !important;
  }
  .ant-collapse-header {
    // border-radius: $mocksalaCardRadious $mocksalaCardRadious 0 0 !important;
    background: rgb(247 121 36 / 40%);
    .ant-collapse-extra {
      a {
        color: $mocksalaSecondaryColor;
      }
    }
    font-weight: bold;
  }
}

.ant-collapse:hover {
  box-shadow: $mocksalaShadowHover;
}
.ant-collapse.no-color {
  .ant-collapse-head {
    background: #ffffff;
  }
}

.isss-shadow {
  box-shadow: $mocksalaShadow;
}
.isss-shadow:hover {
  box-shadow: $mocksalaShadowHover;
}

/* End Jan 25 */

.topheadingifbutton {
  position: relative;
  margin-bottom: 20px;
  clear: both;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
  h3 {
    float: left;
    font-size: 22px;
    letter-spacing: 0.2px;
  }
}

//status active/in-active
.active {
  background-color: green;
}
.inactive {
  background-color: red;
}
.status {
  width: 12px;
  height: 12px;
  display: block;
  // float: right;
  margin-left: 5px;
}

.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
// Dynamic Margin & Padding class:
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;

.addUpdateButton {
  float: right;
}

.ant-tag-checkable {
  border: 1px solid;
}

:root {
  --color-base: #000;
  --color-theme: #f75959;
  --color-theme-button: #5499ff;
  --color-button-hover: #3174da;
  --color-lightgray: #f2f2f2;
  --color-mideumgray: #d0d0d0;
  --color-white: #fff;
  --color-header-bg: #000;
  --color-sidebar-bg: #344149;
  --color-footer-bg: #dddddd;
  --color-green: #60cf86;
  --color-accordian-bg: #fbfbfb;
}
.container {
  position: relative;
  // max-width: 1400px;
  width: 100%;
  margin: 0px auto;
  padding: 0px 24px;
}

.app-layout {
  background-color: #f9f9f9;
  min-height: 100vh;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  line-height: 1.6;
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 0px !important;
}

.ant-menu-sub.ant-menu-inline {
  background: transparent !important;
}

.ant-menu {
  background-color: transparent !important;
}

.img-responsive {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.loginCard .ant-card-body {
  padding: 0px;
}

.logregimagelefty {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  object-position: center;
  height: auto;
}

.logregimagelefty1 {
  width: 100%;
  max-height: 650px;
  object-fit: cover;
  object-position: center;
  height: 650px;
}

.reg-log-form-inner {
  padding: 20px;
}

.ant-input {
  border-radius: 6px;
  font-size: 16px;
}

// .ant-select {
//   // border-radius: 6px;
//   font-size: 16px;
//   // padding: 3px;
//   .ant-select-selector {
//     border-radius: 6px !important;
//   }
// }

.lgrgbg {
  background-image: url(/assets/images/loginbg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.commonbanner {
  position: relative;
}

.ant-layout-footer {
  padding: 15px 0px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  background: var(--color-footer-bg);
}

.mb-15 {
  margin-bottom: 15px;
}

.mbt-15 {
  margin: 15px 0;
}

h2 {
  font-weight: bold;
  margin-bottom: 0px;
}

.greenbutton {
  background-color: var(--color-green);

  color: var(--color-white);
  border-color: var(--color-green);
  font-weight: 600;
  font-size: 15px;
}

.sidebarquestion {
  background-color: var(--color-sidebar-bg);
  border-radius: 10px;
  padding: 25px 10px 25px 30px;
  height: 100%;
}
.filterheading {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-white);
  img {
    padding-right: 5px;
  }
}

.border-bottom {
  border-bottom: 2px solid rgba(84, 153, 255, 1);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.sectionfilter {
  margin: 20px 0 10px;
  clear: both;
  overflow: hidden;
  h5 {
    font-weight: 600;
    font-size: 15px;
    color: var(--color-white);
  }
  [nz-radio] {
    color: #fff;
  }
}

// [nz-radio] {
//   display: block;
//   padding: 8px 5px;
// }
input {
  width: 100px;
  margin-left: 10px;
}
.ant-radio-group {
  padding-left: 10px;
  width: 100%;
}

.ant-collapse {
  border-radius: 0px;
  box-shadow: none !important;
}
.ant-collapse-content {
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border: none;
}
.ant-collapse {
  border: none;
  position: relative;
}
.ant-collapse-content > .ant-collapse-content-box {
  background-color: var(--color-accordian-bg);
  border-radius: 10px;
}
.ant-collapse .ant-collapse-header {
  background-color: var(--color-white);
  padding: 12px 35px 12px 5px !important;
}
// .ant-collapse .ant-collapse-header div.ng-star-inserted {
//   position: absolute;
//   top: 16px;
//   right: 5px;
// }
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-right: 10px;
  padding: 6px;
  background-color: #ededed;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 4px;
}
.ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
  border-top: 5px solid rgba(84, 153, 255, 0.7);
}
.ant-form-item-label > label {
  font-size: 15px;
  font-weight: 600;
  height: auto;
}
.ant-modal-title {
  font-size: 20px;
  font-weight: 600;
}

.updatePrifileModal .ant-modal-footer {
  display: none;
}

.ant-checkbox-disabled {
  cursor: pointer;
}
.questionpagebuttons {
  text-align: left;
  margin-top: -50px;
  button {
    margin-right: 6px;
  }
}
.green-bg-button {
  background-color: var(--color-green);
  border-color: var(--color-green);
}

.black-bg-button {
  background-color: var(--color-base);
  border-color: var(--color-base);
}
.questiontable .ant-checkbox-disabled + span {
  cursor: default;
}
.questiontable
  .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: rgb(2 68 255);
}

.content-space-between {
  display: flex;
  justify-content: space-between;
}

.mb-20 {
  margin-bottom: 20px;
}
.mt-15 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}

.page-header {
  padding: 16px 0;
}

.instruction {
  white-space: pre-line;
}

.instruction-with-alert {
  white-space: pre-line;
}

.instruction-with-alert b {
  font-weight: bold;
  color: red;
}

.itscompleted {
  color: #60cf86;
}

.incomplete .ant-collapse-header {
  border: 1px solid red;
}

.ant-popover-inner {
  max-width: 600px;
}

textarea[readonly] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}
input[readonly] {
  background-color: #f9f9f9;
  cursor: not-allowed;
}
.menubaronly {
  top: 150px;
  background-color: #009688;
  padding: 12px 8px 8px;
  box-shadow: 0 0 10px #838282;
  z-index: 9;
  position: fixed;
  right: 0;
  display: none;
  span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 4px;
    background: #fff;
    background: var(--color-white);
    transition: all 0.4s;
  }
}
.menu_opened .menubar1 {
  transform: rotate(45deg) translate(3px, 1px);
}
.menu_opened .menubar2 {
  display: none;
}
.menu_opened .menubar3 {
  transform: rotate(-45deg) translate(3px, -2px);
}

.ant-page-header {
  padding: 4px 0;
}
.text-right {
  text-align: right !important;
}
  .action-buttons {
    text-align: right !important;
  }

.center {
  text-align: center !important;
}
.width-100{
  width: 100%;
}
.height-100{
  height: 100%;
}
@media (max-width: 1100px) {
  .menubaronly {
    display: block;
  }
  .sidebarquestion {
    display: none;
  }
  .sidebarquestion.openfilter {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 8;
    left: 0;
    right: 0;
    top: 0;
    overflow-y: auto;
  }
  .questionpagebuttons {
    margin-top: 0px !important;
  }
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

@media (max-width: 1024px) {
  .logregimagelefty,
  .logregimagelefty1 {
    display: none;
  }
  .loginCard {
    width: 96% !important;
    max-height: none !important;
  }
  .dasboardboxes {
    margin: 10px 0;
  }
  .copyrighttxt {
    text-align: center !important;
  }
  .footermenu {
    text-align: center !important ;
    margin-top: 7px;
  }
  .commonbanner img {
    min-height: 150px;
    width: auto;
    object-fit: cover;
    object-position: left center;
  }
}

@media (max-width: 1023px) {
  .mobile-mt-10 {
    margin-top: 20px;
  }
}
@media (max-width: 800px) {
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
}

.graduatelist {
  width: 100%;
  .ant-checkbox-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 7px;
  }
}
.action-buttons {
  .ant-btn {
    margin: 2px 2px;
  }
}
.delete-btn {
  margin-right: 15px;
  margin-top: 17px;
}
.collaps-btn {
  display: flex;
  justify-content: space-between;
}
app-section-question {
  width: 100%;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.ant-collapse-item {
  position: relative;
}

app-section-question app-mathjax {
  width: 100%;
  table {
    width: 100%;
    th,
    td {
      text-align: left;
      padding: 2px 5px;
    }
  }
}

.section-time {
  width: 65px;
  .ant-input-number-input {
    margin-left: 0px;
  }
}

.minumum-space-form {
  .ant-form-item-label {
    padding-bottom: 1px;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-divider-horizontal {
    margin: 15px 0 20px;
  }
  .abnormaldividor.ant-divider-horizontal:after,
  .abnormaldividor.ant-divider-horizontal:before {
    transform: translateY(0%);
  }
}

.question-image-card {
  position: relative;
  text-align: center;
  .image-card-del-icon {
    position: absolute;
    margin: 0px !important;
    left: calc(50% - 25px);
    top: calc(50% - 12px);
  }
}

#mathContent img{
  max-width: 200px;
  height: auto;
}
.ellipsis{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.icon-size{
  font-size: 25px;
}
.pagination{
  float: right;
  padding: 15px;
}

.cke_contents {
  resize: vertical;
  min-height: 40px;
}
.loader {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.info-Color{
  color: red;
}
.top-margin{
  margin-top: 3px;
}
.delete{
  color: rgb(255, 0, 0);
  border-color: rgb(241, 169, 166);
}

.ck.ck-toolbar .ck.ck-reset_all{
  white-space: normal; /* Allow toolbar items to wrap */
  overflow: visible; /* Ensure toolbar is fully visible */
}

.ck.ck-toolbar .ck-toolbar__items {
  flex-wrap: wrap; /* Allow toolbar items to wrap */
}

// /* Optional: Ensure the editor resizes properly */
// .ck-editor__main {
//   display: flex;
//   flex-direction: column;
// }

// .ck-editor__editable_inline {
//   flex: 1;
//   overflow-y: auto;
// }

.ck-editor__top {
  position: absolute !important;
  left: 0 !important;
  width: 100% !important;  
  top: -40px !important;
  z-index: 999 !important;
  display: none !important;
}

.ck-editor:has(.ck-editor__main .ck-content.ck-focused) .ck-editor__top{
  display: block !important;
}





app-single-question, .ant-collapse{
  width: 100%;
}

.ck.ck-content.ck-editor__editable{
  resize: vertical;
}
/* Make the toolbar absolutely positioned to not take up layout space */
.sun-editor .se-toolbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  top: -49px;
  left: 0;
  right: 0;
  // z-index: 10;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  /* background-color: #fff; Ensure background is visible */
}

/* Show toolbar when hovering over the editor container */
.sun-editor:hover .se-toolbar {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  pointer-events: auto;
}

/* Ensure the editor container has position relative for absolute positioning context */
.sun-editor {
  position: relative;
}

/* Adjust the editor area to not have space for the toolbar */
.sun-editor .se-wrapper {
  margin-top: 0 !important; /* Override any default margins */
}

/* Add a small buffer space at the top to prevent accidental toolbar hiding */
.sun-editor:hover .se-toolbar::after {
  content: '';
  position: absolute;
  height: 10px;
  left: 0;
  right: 0;
  bottom: -10px;
}

/* Position the sub-menu toolbar (more options) above the main toolbar */
.sun-editor .se-toolbar-more-layer {
  position: absolute;
  bottom: 100%; /* Position above the main toolbar */
  left: 0;
  right:0;
  // z-index: 20; /* Ensure it appears above the main toolbar */
  margin: 0px -1px;
  
}

/* Ensure the sub-menu toolbar is visible when the more options button is clicked */
.sun-editor .se-toolbar-more-layer.se-toolbar-more-layer--visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.sun-editor .se-toolbar-more-layer .se-more-layer{
  border: 1px solid #dadada;
}